export const experiences = [
  {
    job: "Senior Software Engineer",
    company: "Wellx",
    date: "Aug 2024 - present",
    img_path: "./wellx.png",
    detail_link: "",
    skills: "Ruby on Rails, React, Typescript, AWS"
  },
  {
    job: "Full Stack Developer",
    company: "erad",
    date: "Aug 2022 - Aug 2024",
    img_path: "./erad.png",
    detail_link: "",
    skills: "PHP Symfony, React, Typescript, Flask, AWS"
  },
  {
    job: "Programming Teacher",
    company: "Mstars Academy",
    date: "Sep 2021 - May 2022",
    img_path: "./mstars.png",
    detail_link: "",
    skills: "MongoDB, Express, React, Node.js, Teaching"
  },
  {
    job: "Back-End Developer",
    company: "Hippocards",
    date: "Oct 2018 - Aug 2021",
    img_path: "./hippo.png",
    detail_link: "",
    skills: "PHP Laravel, Rest API, MySQL, AWS"
  },
];
