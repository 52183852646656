import React from 'react';
import './App.css';
import './mobile.css';
import './tab.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Routes, Route } from "react-router-dom";
import Home from './Home';
import ExperienceDetail from './components/ExperienceDetail';
import Footer from './components/Footer';
import { Container } from 'react-bootstrap';

export const linkedin = "https://www.linkedin.com/in/bumanerdene/"
export const github = "https://github.com/bumanerdene"

function App() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/experience" element={<ExperienceDetail />} />
    </Routes>
  )
}
const Main = () => {
  return (
    <Container className='main_container w-full pt-[160px] pb-[160px]' style={{ maxWidth: '832px' }}>
      <Home />
      <Footer />
    </Container>)
}

export default App;
