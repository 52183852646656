import React from 'react';
import { Container } from 'react-bootstrap';
import Footer from './Footer';
function ExperienceDetail() {
    return (
        <Container className='main_container w-full pt-[160px] pb-[160px]' style={{ maxWidth: '832px' }}>
            <Container className='body_container'>
                <Container className='experience_container'>
                    <div><h1 className='customH2'>Company Name</h1></div>
                    <div className='mc_1_custom'>
                        <div className='me_description'>
                            <p>I am developer from Mongolia, with 6 years of development experience. Ranging from building back-end app with Laravel, Symfony and Flask and designing user-facing app with Typescript.</p>
                            <p>I love drinking tea and looking forward to trying new flavor everyday. 🍵</p>
                        </div>
                    </div>
                </Container>
            </Container>
            <Footer />
        </Container>
    );
}

export default ExperienceDetail;
