import React from 'react';
import { Container } from 'react-bootstrap';
import Experience from './components/Experience';
import { experiences } from './data/experiences';
import { linkedin } from './App';
import DownloadCV from './components/DownloadCV';
const customLink = {
    textDecoration: 'none', 
    color: '#5043ED'
}
function Home() {
    return (
        <Container className='body_container'>
            <Container className='bio_container'>
                <div className='bc_1'>
                    <img src="/me.jpeg" alt="" className='me_img' />
                </div>
                <div className='bc_2'>
                    <div className='bc_2_1'>
                        <div>
                            <span className='customH1'>Hi, I’m Eny. 👋</span>
                        </div>
                        <div>
                            <p className='description'>I’m <span className='d_color'>Software Engineer & Solutions Architect</span> based in Dubai, UAE. Currently building @Wellx</p>
                        </div>
                    </div>
                    <div className='bc_2_2'>
                        <a href='#about_me' className='d_button no-deco-link'>About me</a>
                        <a href={linkedin} target='_blank' rel="noreferrer" className='d_button no-deco-link'>Contact me</a>
                        <DownloadCV />
                    </div>
                </div>
            </Container>
            <Container className='experience_container'>
                <div><h1 className='customH2'>My experience</h1></div>
                {/* Experience component */}
                {experiences.map((experience) => <Experience skills={experience.skills} job={experience.job} company={experience.company} date={experience.date} img_path={experience.img_path} detail_link={experience.detail_link} />)}
            </Container>
            <Container className='me_container' id='about_me'>
                <div className='mc_1'>
                    <div><span className='customH2'>About me</span></div>
                    <div className='me_description'>
                        <p>I am software engineer from Mongolia 🇲🇳, with 6 years of development experience in various startup projects.
                            I deeply enjoy working with data, and building robust back-end structures.
                        </p>
                        <p>Recently, I joined the health tech industry to contribute to projects that help people stay healthy.</p>
                        <p>
                            <i>I don't know if it's just because I'm getting older 🙃, but being healthy has become the most crucial part of my life. Isn't it cool that i can stay healthy while developing apps that support others in being healthy too? check out <a href='https://wellxai.com/' target='blank' style={customLink}>Wellx</a>. </i>

                        </p>
                        <p>I was working with fintech startup called erad and in the last two years, I developed multiple features successfully, including Loan Management System, Underwriting & Scoring System and Python PDF convertor.
                        </p>
                        <p>Additionally, I love trees, mountain, clean blue sky they just make me happy also i like tea, never going to get bored of trying new flavors 🍵</p>
                        <p><mark>One more highlight,</mark> more athlethic side of me enjoys playing tennis 🎾. Looking forward to change this status to "i play tennis quite well". But for now, yes i am a beginner but i play with Wilson blade pro 💚. </p>
                    </div>
                </div>
                <div className='mc_2'>
                    <div>
                        <img src='/randoms/DSCF2599.webp' alt='my_random_pic' className='tea-img' />
                    </div>
                    <div className='mc_2_inner'>
                        <img src='/randoms/DSCF3048.webp' alt='my_random_pic' height="100%" className='tea-img' />
                        <img src='/randoms/DSCF2037.webp' alt='my_random_pic' height="100%" className='tea-img' />
                    </div>
                    <div><img src='/randoms/DSCF2303.webp' alt='my_random_pic' className='tea-img' /></div>
                </div>
            </Container>
        </Container>
    );
}

export default Home;
