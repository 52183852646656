import React from 'react';

interface ExperienceProps {
    skills: string;
    job: string;
    company: string;
    date: string;
    img_path: string;
    detail_link: string;
    // onButtonClick: () => void; 
}

function Experience({ skills, job, company, date, img_path, detail_link }: ExperienceProps) {
    return (
        <a href="#ec_1_tmp" className='ec_link'>
            <div className='ec_1' id='ec_1_tmp'>
                <div><img src={img_path} className='company_logo' alt='company logo'/></div>
                <div className='ec_1_2'>
                    <div><span className='ec_1_2_title_p1'>{job} </span><span className='ec_1_2_title_p2'>at {company}</span></div>
                    <div className='ec_1_2_desc_container'>
                        <span className='ec_1_2_desc_blue'>{skills}</span>
                        <span className='ec_1_2_desc_dot'><img src={"./dot.png"} className='' width={"4px"}  alt='dot logo'/></span>
                        <span className='ec_1_2_desc'>{date}</span>
                    </div>
                </div>
                <div>
                    <button className='d_button_arrow'><img src='/arrow.png' className='social_logo'  alt='arrow logo'/></button>
                </div>
            </div>
        </a>
    );
}

export default Experience;
