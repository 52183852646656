import React from 'react';
import { Container } from 'react-bootstrap';
import { linkedin, github } from '../App';
import DownloadCV from './DownloadCV';
function Footer() {
  return (
    <Container className='footer_container'>
      <div className='fc_1_1'>
        <span className='customH2'>Get in touch</span>
        <span className='me_description'>Happy to talk new projects, or opportunities. </span>
      </div>
      <div className='fc_1_2'>
        <div className='fc_1_2_1'>
          <button onClick={(e) => {  
             window.open(linkedin, '_blank');
          }} className='git_btn'>Get in touch</button>
        </div>
        <div className='fc_1_2_2'>
          <a href={linkedin} rel="noreferrer" target="_blank" className='d_button_w_logo'><img src='/linkedin.png' className='social_logo' alt='linkedin logo'/></a>
          <a href={github} rel="noreferrer" target="_blank" className='d_button_w_logo'><img src='/github.png' className='social_logo' alt='gh logo'/></a>
          <DownloadCV />
        </div>
      </div>
    </Container>
  );
}

export default Footer;
